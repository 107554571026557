<template>
  <div>
    <CRow class="col-md-12">
      <label class="col-md-2">User : </label>
      <span class="col-md-6">{{ input.userid }}</span>
      <!-- <CSelect
                      class="col-md-6"
                      :options="user_opt"
                      :value.sync="input.userid"
                      placeholder='select'
                      /> -->
    </CRow>
    <CRow class="col-md-12">
      <label class="col-md-2">Plans : </label>
      <CSelect class="col-md-6" track-by="id" :options="plan_opt" :value.sync="input.plan_name" placeholder='select'
        @change="checkPlan()" />
    </CRow>

    <CRow class="col-md-12">
      <label class="col-md-2">Expiry Date :</label>
      <label class="col-md-2">{{ changeDateFormate(allocatedate) }}</label>
      <CButton class="float-right" type="submit" v-on:click="show_date == false ? show_date = true : show_date = false"
        color="info">Change Date</CButton>
    </CRow>

    <CRow class="mt-2" v-show="show_date">
      <label class="col-md-2"></label>
      <date-time-picker v-if="renderComponent" class="col-md-6 col-3" :singleDate="true" :startDate="allocatedate"
        @onChange="dateFilter" size="sm" />
    </CRow>

    <CRow class="col-md-12 mt-4">
      <CCol class="col-md-12">
          <CButton class="col-md-12" type="submit" v-on:click="onSubmit()" variant="" color="success">
            {{ edit_allocate_plan ? "Save" : "Submit" }}</CButton>
      </CCol>
    </CRow>
  </div>
</template>
  
<script>
import Multiselect from "vue-multiselect";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import ManagerService from './../../services/managerControlService'
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
export default {
  components: { Multiselect, DateTimePicker },
  props: { planData: Object },
  data() {
    return {
      renderComponent: true,
      show_date: false,
      allocatedate: new Date(),
      edit_allocate_plan: false,
      input: { expiry_date: '', userid: '', plan_name: '', planid: '' },
      tableperpage: 20,
      perPage: [20, 50, 100],
      items: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      users: [],
      plan_opt: [],
      user_opt: [],
      plan_data: [],
      status_opt: [{ label: "Active", value: true },
      { label: "Inactive", value: false }],
    }
  },
  computed: {

  },
  watch: {
    planData(val) {
      this.input = { expiry_date: '', userid: '', plan_name: '', planid: '' }
      this.edit_allocate_plan = false
      if (val) {
        this.input.userid = val.email
        if (val.user_plan_docs.length > 0) {
          this.input = val.user_plan_docs[0]
          this.input.plan_name = this.plan_opt.filter(t => t.id == val.user_plan_docs[0].planid ? val.user_plan_docs[0].planid : '')
          this.input.plan_name = this.input.plan_name[0].value
          this.input.expiry_date = new Date(this.input.expiry_date)
          this.allocatedate = this.input.expiry_date
          this.edit_allocate_plan = true
          this.show_date = false
          this.renderComponent = false;
          this.$nextTick(() => {
            this.renderComponent = true;
          });
        }
      }
    }
  },
  async mounted() {
    this.getPlans()
  },
  methods: {
    treatAsUTC(date) {
      var result = new Date(date);
      result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
      return result;
    },
    findleftDays(startDate, endDate) {
      var millisecondsPerDay = 24 * 60 * 60 * 1000;
      var t = Math.floor(this.treatAsUTC(endDate) / millisecondsPerDay) - Math.floor(this.treatAsUTC(startDate) / millisecondsPerDay);
      return t
    },
    changeDateFormate(d) {
      var p = new Date(d);
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(p);
      let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(p);
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(p);
      let hh = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(p);
      var dd
      if (this.change_date) {
        dd = `${ye} ${mo} ${da} , ${hh}`;
      }
      else { dd = `${ye} ${mo} ${da}` }
      return dd
    },
    dateFilter(data) {
      this.allocatedate = data.startDate
      this.input.expiry_date = this.allocatedate
    },
    checkPlan() {
      let plan = this.plan_data.find(t => t.plan_name == this.input.plan_name)
      this.input.planid = plan._id
      var date = new Date();
      switch (plan.validity) {
        case 'Weekly': this.input.expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7)
          break;
        case 'Monthly': this.input.expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 30)
          break;
        case 'Quarterly': this.input.expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 90)
          break;
        case 'Yearly': this.input.expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 365)
          break;
        default: this.input = date
      }
      this.dateFilter({ startDate: new Date(this.input.expiry_date.setHours(23, 59)) })
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    async onSubmit() {
      if (this.Validate()) {
        try {

          var response
          if (this.edit_allocate_plan) {

            var payload = {
              _id: this.input._id, userid: this.input.userid, planid: this.input.planid,
              expiry_date: this.input.expiry_date
            }
            response = await ManagerService.EditAllocatePlans(payload);
          }
          else {
            var payload = {
              userid: this.input.userid, planid: this.input.planid,
              expiry_date: this.input.expiry_date
            }
            response = await ManagerService.AllocatePlans(payload);
          }
          if (response.result) {
            Swal.fire({ title: 'Success!', text: response.message, icon: 'success', confirmButtonText: 'Close', position: 'top-end', showConfirmButton: false, timer: 1000 })
          }
          else {
            Swal.fire({ title: 'Error!', text: response.message, icon: 'error', confirmButtonText: 'Close', position: 'top-end', showConfirmButton: false, timer: 1000 })
          }
          this.edit_allocate_plan = false
          this.input = {}
          this.getPlans()
        } catch (e) {
          console.log('connection error')
        }
      }
      else {
        Swal.fire({ title: 'Error!', text: "All details Required!", icon: 'error', confirmButtonText: 'Close', position: 'top-end', showConfirmButton: false, timer: 1000 })
      }
      this.$emit('planModel')
    },
    Validate() {
      if (this.input.planid && this.input.expiry_date && this.input.userid) {
        return true
      }
      else {
        return false
      }
    },
    async getPlans() {
      let res = await ManagerService.getplan();
      this.plan_data = res.message
      this.plan_data = this.plan_data.filter(t => t.status)
      this.plan_opt = this.plan_data.map(p => { return { value: p.plan_name, id: p._id } })

    }
  }
}
</script>
  
<style>
.b_card {
  background-color: white;
  /* box-shadow: rgb(136, 136, 136) 2px 5px 7px 2px; */
}

.bg_page {
  background: white;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20px;
  /*position: fixed;*/
  /*background: rgb(2, 0, 36);*/
  /*background: linear-gradient(152deg, rgba(2, 0, 36, 1) 0%, rgba(15, 15, 126, 1) 49%, rgba(0, 212, 255, 1) 100%);*/
}

.logout:hover {
  background-color: #b82e2b;
}

.v_tag {
  overflow-y: scroll;
  max-height: 100px;
}
</style>
  