<template>
    <div>
    <CRow>
        <CCol class="col-md-12">
            <label class="col-3 col-12">Select User Role</label>
            <CSelect class="col-md-3 col-12" v-model="type" :options="user_types" />
        </CCol>
    </CRow>
    <CRow class="col-md-12 col-12">
        <CCol class="col-md-12 col-12" v-for="role in roles" :key="role.role">
            <div v-show="role.role == type" class="role-container">
                <h2>Role ({{ capitalizeFirstLetter(role.role) }})</h2>
                <p>{{ role.overview }}</p>

                <div class="responsibilities">
                    <h3>Responsibilities:</h3>
                    <ul>
                        <li v-for="(tasks, category) in role.responsible" :key="category">
                            <strong>{{ category }}</strong>
                            <ul>
                                <li v-for="task in tasks" :key="task">{{ task }}</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="permissions">
                    <h3>Permissions:</h3>
                    <ul>
                        <li v-for="permission in role.permissions" :key="permission">{{ permission }}</li>
                    </ul>
                </div>
            </div>
        </CCol>
    </CRow>
    </div>
</template>


<script>
import ManagerService from './../../services/managerControlService'
export default {
    name: 'Roles',
    data() {
        return {
            roles: [],
            type: 'user',
            user_types: []
        }
    },
    created() {
        this.getRoles();
    },
    methods: {
        async getRoles() {
            let res = await ManagerService.getRoles();
            this.roles = res.data;
            this.user_types = this.roles.map((role) => role.role);
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    }
}
</script>

<style scoped>
.role-container {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 20px;
}

.responsibilities {
    margin-top: 10px;
}

.permissions {
    margin-top: 10px;
}
</style>