<template>
  <div>
      <CCard>
      <CCardHeader>
        <CIcon name="cil-user" /><strong>User List</strong>
      </CCardHeader>
      <CCardBody>
          <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect
                size="sm"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div>
      <!-- Main table element -->
      <CDataTable
      :items="items"
      :fields="fields"
      :dark="false"
      :items-per-page="tableperpage"
      pagination
      sorter
      hover
      striped
      border
      small
      fixed
      table-filter
      light
    >
    <template #username="{item}">
        <td v-if="item.role != 'manager'">
          <span class="link res_text" @click="openModel(item)">{{ item.username }}</span>
        </td>
    </template>
    <template #email="{item}">
        <td v-if="item.role != 'manager'">
          <span class="res_text">{{ item.email }}</span>
        </td>
    </template>
    <template #plan="{item}">
        <td v-if="item.role != 'manager'">
          <span class="link" @click="openPlanModel(item)" v-if="item.user_plan_docs.length > 0">
             <span v-if="getPlanName(item.user_plan_docs[0].planid) !=false">{{ getPlanName(item.user_plan_docs[0].planid) }}</span>
             <span v-else><CButton color="info" size="sm" @click="openPlanModel(item)">Allocate Plan</CButton></span>
          </span>
          <CButton v-else  color="info" size="sm" @click="openPlanModel(item)">Allocate Plan</CButton>
         </td>
    </template>
    <template #roleinfo="{item}">
        <td v-if="item.role != 'manager'">
          <span v-if="item.role == 'csp'">CSP</span>
          <span v-else-if="item.role == 'subUser'">Sub User</span>
          <span v-else>
            <CSelect
              required
              :value.sync="item.role"
              :options="role_opt"
              @change="changeRole(item)"
            />
          </span>
        </td>
    </template>

    <template #inspector="{item}">
        <td v-if="item.role != 'manager'">
          <!-- <CRow class="col-md-12"> -->
          <!-- <CSwitch
          color="success"
          :checked="item.iwitness"
          @update:checked="iwitnessUpdate(item)"
          shape="pill"
        /> -->
        <CSelect :value.sync="item.iwitness" style="border-radius: 5px;" :style="item.iwitness?'border: 2px solid green;':'border: 2px solid red;'"
            :options="iwitness_opt"
            @change="iwitnessUpdate(item)"
            ></CSelect>
        <!-- <CBadge class="ml-2 mb-2" v-show="item.iwitness" color="success"> Enabled</CBadge>
        <CBadge class="ml-2 mb-2" v-show="item.iwitness == false" color="danger"> Disabled</CBadge> -->
        <!-- </CRow> -->
        </td>
    </template>

    <template #isactive="{item}">
      <td v-if="item.role != 'manager'">
      <!-- <span @click="openModel(item)">
          <CImg src="img/logo/edit.png" class="img-fluid" title="Edit"
                             style="cursor: pointer;"
                             ></CImg>
          </span> -->
        <span>
          <CSwitch
          v-if="preventLoad"
          color="success"
          :checked="item.isactive"
          @update:checked="update(item)"
          shape="pill"
          />
        <!-- <CImg v-if="item.isactive == true" src="img/logo/push.png" class="img-fluid ml-1" title="Inactive" :pressed.sync="item.status"
          style="cursor: pointer;"
          ></CImg>
        <CImg v-if="item.isactive == false" src="img/logo/play.png" class="img-fluid ml-1" title="Active" :pressed.sync="item.status"
            style="cursor: pointer;"
        ></CImg> -->
        </span>
         <!-- <span v-if="item.isactive == true"> Deactivate</span>
        <span v-if="item.isactive == false"> Activate</span> -->
        <CBadge class="ml-2 mb-2" v-show="item.isactive" color="success"> Active</CBadge>
        <CBadge class="ml-2 mb-2" v-show="item.isactive == false" color="danger"> Inactive</CBadge>
          </td>
    </template>
    <template #login="{item}">
      <td>
        <span class="link" @click="RedirectLogin(item)">Login</span>
      </td>
    </template>
    <template #default="{item}">
      <td>
        <span class="link" @click="genDefaultCamp(item)">create campaign</span>
      </td>
    </template>
    <template #roleinfo-header="data">
      <CRow>
           <CCol><span>Role</span></CCol>
           <CCol class="text-right"><span @click="roleinfo=true"><CIcon name="cil-info"/></span></CCol>
          </CRow>
    </template>
    </CDataTable>
    </CCardBody></CCard>

    <!-- show plan model  -->

    <CModal
      title="Allocate Plan"
      :show.sync="plan_model"
      size="lg"
      :centered="false"
      color="dark">
      <AllocatePlanModel :planData="planInfoData" @planModel="getUsers()"/>

      <template #footer>
        &nbsp;
      </template>
    </CModal>
    <!-- show roleinfo model  -->
    <CModal
      title="Role Info"
      :show.sync="roleinfo"
      size="lg"
      :centered="true"
      scrollable
      color="dark">
      <RolesInfo/>
      <template #footer>
        &nbsp;
      </template>
    </CModal>
    <!-- Show Model -->
    <CModal
      title="Edit user"
      :show.sync="show_model"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <CCard class="mx-4 mb-0 text-right">
            <CCardBody class="p-4">
              <CForm>
                <CRow class="col-md-12">
                <label class="col-md-3">Email :</label>
                <CInput
                  class="col-md-8"
                  placeholder="Email"
                  autocomplete="email"
                  prepend="@"
                  v-model="input.email"
                />
                </CRow>
                <CRow class="col-md-12">
                <label class="col-md-3">Username :</label>
                <CInput
                  class="col-md-8"
                  placeholder="Username"
                  autocomplete="username"
                  v-model="input.username"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                </CRow>

                <CRow class="col-md-12">
                <label class="col-md-3">Company Name :</label>
                <CInput
                  class="col-md-8"
                  placeholder="Company name"
                  autocomplete="username"
                  v-model="input.companyName"
                >
                  <template #prepend-content><CIcon name="cil-building"/></template>
                </CInput>
                </CRow>
                <CRow class="col-md-12">
                <label class="col-md-3">Company Address :</label>
                <CInput
                  class="col-md-8"
                  placeholder="Company Address"
                  autocomplete="username"
                  v-model="input.companyAddress"
                >
                  <template #prepend-content><CIcon name="cil-map"/></template>
                </CInput>
                </CRow>

                <CRow class="col-md-12">
                <label class="col-md-3">Company Website :</label>

                <CInput
                class="col-md-8"
                  placeholder="Company Website"
                  autocomplete="username"
                  v-model="input.companyWebsite"
                >
                  <template #prepend-content><CIcon name="cil-notes"/></template>
                </CInput>
                </CRow>

                <CRow class="col-md-12">
                <label class="col-md-3">Number of Employee :</label>

                <CInput
                  class="col-md-8"
                  placeholder="Number of employees"
                  autocomplete="username"
                  type="number"
                  v-model="input.num_of_employee"
                >
                  <template #prepend-content><CIcon name="cil-user-x"/></template>
                </CInput>
                </CRow>

                <CRow class="col-md-12">
                <label class="col-md-3">Skype :</label>

                <CInput
                  class="col-md-8"
                  placeholder="Skype"
                  autocomplete="username"
                  type="text"
                  v-model="input.skype"
                >
                  <template #prepend-content><CIcon name="cil-chat-bubble"/></template>
                </CInput>
                </CRow>

                <CRow class="col-md-12">
                <label class="col-md-3">Password :</label>
                
                <CInput
                  class="col-md-8"
                  placeholder="Password"
                  type="password"
                  autocomplete="new-password"
                  v-model="input.password"
                  id="password"
                >
                  <template #prepend-content>
                    <span @click="togglePassword('password')">
                      <CIcon v-show="showPassword == true" name="cil-lock-unlocked"/>
                      <CIcon v-show="showPassword == false" name="cil-lock-locked"/>
                    </span>
                  </template>
                </CInput>
                </CRow>

                <CRow class="col-md-12">
                <label class="col-md-3">Repeat password :</label>
                <CInput
                  class="col-md-8 mb-4"
                  placeholder="Repeat password"
                  type="password"
                  autocomplete="new-password"
                  v-model="input.password1"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                </CRow>
                
              </CForm>
            </CCardBody>
    </CCard>
    <template #footer>
      <CButton color="success" block @click="Edit()">Update</CButton>
      </template>
    </CModal>


  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import ManagerService from './../../services/managerControlService'
import AllocatePlanModel from './AllocatePlanModel.vue'
import RolesInfo from './Roles.vue'
  export default {
    components: {Multiselect,RolesInfo,AllocatePlanModel},
    data() {
      return {
        showPassword:false,
        plan_data:[],
        planInfoData:{},
        plan_model:false,
        preventLoad: true,
        roleinfo:false,
        iwitness_opt: [
          { value: true, label: "Inspector Enabled" },
          { value: false, label: "Inspector Disabled" }
        ],
        role_opt: [
          { value: "user", label: "User" },
          { value: "moderator", label: "Moderator" }
        ],
        email:'',
        input:{},
        show_model:false,
        tableperpage:20,
        perPage:[20,50,100],
        items: [],
        fields: [
          { key: 'username', label: 'User Name'},
          { key: 'email', label: 'Email'},
          { key: 'plan', label: 'Plan Info',sortable: true },
          // { key: 'skype', label: 'Skype'},
          { key: 'roleinfo', label: 'Role', sortable: false },
          { key: 'companyName', label: 'Company'},
          { key: 'inspector', label: 'Inspector Enable/Disable'},
          {key: 'login',label: 'Login'},
          {key: "default", label: 'Default'},
          {key: 'isactive',label: 'User Status' , sortable:true}
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: {},
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        users:[],
      }
    },
    computed: {
      
    },
   async mounted() {
      if(window.localStorage.getItem('user') == 'null' || window.localStorage.getItem('user') != 'manager') {
        alert('Please Enter User Name and Password')
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          window.localStorage.setItem("email", null);
        this.$router.push('/pages/login')
      }
      this.email = window.localStorage.getItem('email')
      this.role = window.localStorage.getItem('role')
      if(localStorage.getItem("user_table") != null)
      this.tableperpage = parseInt(localStorage.getItem("user_table"));
      this.getUsers()
      this.getPlans()
    },
    methods: {
      getPlanName(id){
        let plan = this.plan_data.filter(t => t._id == id)
        let planname = plan[0]?plan[0].plan_name:false
        return planname
      },
      async getPlans() {
      let res = await ManagerService.getplan();
      this.plan_data = res.message
      this.plan_data = this.plan_data.filter(t => t.status)
      },
      async Edit(){
         let response
          try {
            if(this.validate()){
            let postdata ={
              tobeModify:this.input 
            }
             response = await ManagerService.editUser(postdata)
             if(response.result){
               Swal.fire({title: 'Success!',text: "Updated",icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
             }
            }
           } catch(e){
              console.error("Error in login")
              console.log(e)
          }
      },
      openPlanModel(data){
        this.planInfoData = {}
        this.plan_model = true
        this.planInfoData = data
      },
      async changeRole(user){
        if(confirm('Are you sure to change role?')){
          let response
          try {
              let payload = {
                userEmailTobeChanged : user.email,
                role: user.role
              }
            response = await ManagerService.changeUserRole(payload)
            if (response.result == true) {
                Swal.fire({title: 'Success!',text: "Role updated successfully",icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
                }
                else {
                    Swal.fire({title: 'Error!',text: "Invalid username and password",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
                }
          } catch(e){
            console.error("Error in login")
            console.log(e)
          }
        }
        this.getUsers()
      },
      async genDefaultCamp(data) {
        let payload = {
          emailForDefault: data["email"]
        }
        try {
            let response = await ManagerService.genDefaultCampign(payload)
            console.log(response)
           if (response.result == true) {
              Swal.fire({title: 'Success!',text: "Default Campaign Generate Success!!",icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
              this.getUsers()
              }
              else {
                  Swal.fire({title: 'Error!',text: "Default Campign Generate Fails!!",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
              }
        } catch (e) {
          console.error("Error in generate campign api")
          console.error(e)
        }
      },
    async RedirectLogin(d){
      var postdata = { email: this.email, password: 'test', emailToBeLogin: d.email}
      try {
        let response = await ManagerService.wildlogin(postdata)
        console.log(response)
        if(response.result == true){
          let routeData = this.$router.resolve({path: '/pages/login', query: { 'user': response.message.role, 'email': response.message.email, 'status': response.message.isactive, 'iwitness': response.message.iwitness,'wildLogin':true}});
          window.open(routeData.href);
          const newWindow = window.open(routeData.href);
          this.$router.push('/pages/logout')
          window.close();
        }
      } catch(e){
        console.error("Error in login")
        console.log(e)
      }
    },
    async iwitnessUpdate(formData){
      if(confirm('Are you sure to change iwitness permission?')){
      let response
        try {
            // if(formData.iwitness == true){
            //     formData.iwitness = false
            // }
            // else{
            //     formData.iwitness = true
            // }
            let payload = {
                          email : formData.email,
                          iwitness: formData.iwitness
                        }
          response = await ManagerService.changeiwitness(payload)
          if (response.result == true) {
              Swal.fire({title: 'Success!',text: "Iwitness updated successfully",icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
              this.getUsers()
              }
              else {
                  Swal.fire({title: 'Error!',text: "Invalid username and password",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
              }
        } catch(e){
          console.error("Error in login")
          console.log(e)
        }
      }else{
        this.getUsers()
      }
    },
      validate(){
        if(this.input.email){
        if(this.input.password.length < 6 && this.input.password == ''){
          Swal.fire({title: 'Error!',text: "Please use minimum 6 digit password",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
            return false
          }
        else if(this.input.password.length >= 6 &&this.input.password == this.input.password1 && this.input.username != '' && this.input.password != '' ){
            return true
        }
        else{
          Swal.fire({title: 'Error!',text: "Please Enter all details",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
            return false
        }
        }
        else{
          Swal.fire({title: 'Error!',text: "User Email required",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
      },
      openModel(data){
        this.show_model = true;
        this.input = data
      },
      checkPage(){
          localStorage.setItem("user_table", this.tableperpage);
        },
       async getUsers(){
        this.plan_model = false
            let response
        try {
            response = await ManagerService.getuser()
            this.users = response.message
            this.users = response.message.filter(t=>{
            if(t.role != 'manager')
            return t
            })
            this.items = this.users
            this.totalRows = this.items.length
        } catch(e){
            console.error("Error in login")
            console.log(e)
        }
        },
     async update(formData){
      this.preventLoad = false;
      if(formData.user_plan_docs.length == 0){
        alert('Please allocate a plan before activating the user.')
        this.$nextTick(() => {
        // Revert the switch state
        this.preventLoad = true;
      });
        return;
      }
      if (confirm('Are you sure to change User status?')) {
        this.preventLoad = true;
        let response
        try {
            if(formData.isactive == true){
                formData.isactive = false
            }
            else{
                formData.isactive = true
            }
          response = await ManagerService.changestatus(formData)
          if (response.result == true) {

              Swal.fire({title: 'Success!',text: "data updated successfully",icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
              this.getUsers()
              }
              else {
                  Swal.fire({title: 'Error!',text: "Invalid username and password",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
              }
        } catch(e){
          console.error("Error in login")
          console.log(e)
        }
      }
      this.$nextTick(() => {
        // Revert the switch state
        this.preventLoad = true;
      });
      },
      togglePassword(id){
          const password = document.getElementById(id);
          this.showPassword = this.showPassword === false ? true: false
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
      }
    }
  }
</script>

<style>
  .b_card{
    background-color: white;
    /* box-shadow: rgb(136, 136, 136) 2px 5px 7px 2px; */
  }

  .bg_page {
    background: white;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 20px;
    /*position: fixed;*/
    /*background: rgb(2, 0, 36);*/
    /*background: linear-gradient(152deg, rgba(2, 0, 36, 1) 0%, rgba(15, 15, 126, 1) 49%, rgba(0, 212, 255, 1) 100%);*/
  }
  .logout:hover{
    background-color: #b82e2b;
  }
  .v_tag{
    overflow-y: scroll;
    max-height: 100px;
  }
  .link{
  color: blue;
  cursor: pointer;
}
</style>
